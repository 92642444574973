<template>
  <b-overlay :show="loading" rounded="sm">
    <div class="form row">
      <div class="col-12">
        <validation-provider v-slot="{ errors }" :name="$t('Country')" :rules="rules.country">
          <euro-select v-model="form.country" :label="`${$t('Country')}*`" :error-messages="errors"
            :options="countriesSelect" searchable></euro-select>
        </validation-provider>
      </div>
      <div class=" col-12">
        <validation-provider v-slot="{ errors }" :name="$t('Fiscal Code')" vid="fiscalid" :rules="rules.fiscalid">
          <euro-input v-model="form.fiscalid" :label="`${$t('Fiscal Code')}*`" :error-messages="errors"></euro-input>
        </validation-provider>
      </div>
      <div class="col-6">
        <validation-provider v-slot="{ errors }" vid="from_date" :name="$t('From date')" :rules="rules.from_date">
          <date-picker v-model="form.from_date" :overrides="fromDateOverrides" :error-messages="errors"
            placeholder="YYYY-MM-DD" :label="$t('From date')">
          </date-picker>
        </validation-provider>
      </div>
      <div class="col-6">
        <validation-provider v-slot="{ errors }" :name="$t('To date')" vid="to_date" :rules="rules.to_date">
          <date-picker v-model="form.to_date" :overrides="toDateOverrides" :error-messages="errors"
            :label="$t('To date')">
          </date-picker>
        </validation-provider>
      </div>
      <div v-if="!optional" class="col-12">
        <validation-provider v-slot="{ errors }" :name="$t('Attachment')" vid="attachment_tus" :rules="rules.attachment"
          class="w-100">
          <euro-tus-file-input :label="$t('Attachment')" :error-messages="errors" accept="application/pdf"
            :hint="$t('Accept .pdf - max size 20MB')" :attachment="existingForm.attachment" @change="(tusId) => {
              form.attachment_tus = tusId;
            }
              "></euro-tus-file-input>
        </validation-provider>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import moment from "moment";
import datePicker from "@/view/components/datePicker/datePicker.vue";
import { mapState } from "vuex";
import { countryChangeSwal } from "@/core/helpers/swal";
const now = new Date();
export default {
  components: {
    datePicker,
  },
  props: {
    existingForm: {
      type: Object,
      default: () => ({}),
    },
    isNotRequired: {
      type: Boolean,
      default: false,
    },
    defaultCountry: {
      type: String,
      default: "",
    },
    optional: {
      type: Boolean,
      default: false,
    },
    isCreating: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      maxDate: now,
      form: {
        country: "",
        counterpart: "",
        fiscalid: "",
        from_date: null,
        to_date: null,
        attachment_tus: null,
      },
      administrativeStructures: [],
      loading: false,
    };
  },

  computed: {
    ...mapState("constants", ["countries"]),

    fiscalValidatorArgs() {
      const country = this.form.country || null;
      return [!this.existingForm, country, true, null, null];
    },

    rules() {
      return {
        country: {
          required: !this.isNotRequired,
        },
        counterpart: {
          required: false,
        },
        fiscalid: {
          required: !this.isNotRequired,
          fiscal_id: [...this.fiscalValidatorArgs],
        },
        attachment: {},
      };
    },

    optionalFields() {
      return !!(this.optional && this.form.fiscalid.length);
    },

    toDateOverrides() {
      return this.form.from_date !== "Invalid date" ? { minDate: this.form.from_date } : null;
    },
    fromDateOverrides() {
      return this.form.from_date ? { maxDate: moment() } : null;
    },

    stringMaxDate() {
      return moment(this.maxDate, true).format("YYYY-MM-DD");
    },

    countriesSelect() {
      return this.countries
        .map((x) => ({ text: this.$t(x.name), value: x.iso }))
        .sort((a, b) => a.text.localeCompare(b.text));
    },

    administrativeStructuresByCountry() {
      if (!this.form.country) {
        return this.administrativeStructures;
      }

      return this.administrativeStructures.filter((el) => el.country_of_establishment === this.form.country);
    },

    administrativeStructuresSelectable() {
      return this.administrativeStructuresByCountry.map((el) => ({
        value: el.id,
        text: `${el.name} - ${el.country_of_establishment}`,
      }));
    },
  },

  watch: {
    defaultCountry: {
      immediate: true,
      handler(val) {
        if (val && !this.form.country) this.form.country = val;
      },
    },
    'form.country': {
      handler(newVal, oldVal) {
        //control the swal for dont do loop
        if (this.isChangingCountry) {
          this.isChangingCountry = false;
          return;
        }
        if (newVal !== null) {
          if (this.existingForm.hasOwnProperty('country') && this.existingForm.country != newVal) {
            countryChangeSwal({
              text: this.$t("You are about to change the country. Are you sure?"),
            }).then(res => {
              if (!res.isConfirmed) {
                this.isChangingCountry = true;
                this.form.country = oldVal;
              }
            });
          }
        }
      },
      inmediate: true
    },
  },

  async mounted() {
    this.loading = true;
    if (this.existingForm) {
      this.form = { ...this.form, ...this.existingForm };
    }
    // this.administrativeStructures = await getCounterparts({ document: DOCUMENT_COUNTERPART.FISCAL_ID });
    this.loading = false;
  },

  methods: {
    moment,
    //updateForm is called from parent component with a refs
    updateForm() {
      this.$emit('input', this.form);
    }
  },
};
</script>
