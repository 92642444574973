<template>
  <validation-observer ref="validationObserver" v-slot="{ handleSubmit }">
    <b-modal id="legal-person-create-fiscal-modal" hide-footer @hidden="onModalHidden">
      <template #modal-header>
        <div class="w-100 h-100 d-flex justify-content-between align-items-center">
          <h3 class="mb-0">{{ $t('Create Fiscal Code') }}</h3>
          <div class="d-flex align-items-center">
            <b-button variant="success" class="mr-2" :class="{ 'spinner spinner-light spinner-right': isSaving }"
              @click="handleSubmit(submit)">
              {{ $t('Save') }}
            </b-button>
            <b-button @click="closeModal">
              {{ $t('Cancel') }}
            </b-button>
          </div>
        </div>
      </template>
      <legal-person-fiscal-form ref="formComponent" :existing-form="existingForm"
        :default-country="legalPerson.country_of_establishment" is-creating
        @input="onLegalPersonFiscalFormInput"></legal-person-fiscal-form>
    </b-modal>
  </validation-observer>
</template>

<script>
import LegalPersonFiscalForm from "@/view/components/forms/legal-person/LegalPersonFiscalForm.vue";

import Swal from "sweetalert2";
import CompanyFiscalService from "@/core/services/company/company-fiscal.service";
import { backendErrorSwal } from "@/core/helpers/swal";
export default {
  components: {
    LegalPersonFiscalForm,
  },

  props: {
    legalPerson: {
      type: Object,
      required: true,
    },
    existingForm: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      form: null,
      isSaving: false,
      apiErrors: null,
    };
  },

  computed: {
    isEdit() {
      return Object.keys(this.existingForm).length > 0
    },
  },
  watch: {
    apiErrors: {
      deep: true,
      handler(val) {
        const formObs = this.$refs.validationObserver;
        const newErrors = {
          country: [...(val?.country ?? [])],
          fiscalid: [...(val?.fiscalid ?? [])],
          from_date: [...(val?.from_date ?? [])],
          to_date: [...(val?.to_date ?? [])],
          is_active: [...(val?.is_active ?? [])],
          attachment_tus: [...(val?.attachment_tus ?? [])],
          counterpart: [...(val?.counterpart ?? [])],
        };
        formObs.setErrors({ ...newErrors });
      },
    },
  },

  methods: {
    closeModal() {
      this.$bvModal.hide("legal-person-create-fiscal-modal");
    },

    openModal() {
      this.$bvModal.show("legal-person-create-fiscal-modal")
    },

    onModalHidden() {
      this.form = null;
    },

    async submit() {
      this.$refs.formComponent.updateForm();
      this.isSaving = true;
      try {
        if (this.isEdit) {
          await CompanyFiscalService.update(this.form, this.form.id)
        } else {
          await CompanyFiscalService.create({ ...this.form, company: this.legalPerson?.id });
        }
        this.$emit("refresh");
        this.closeModal();
        let title = this.$t("Document created")
        if (this.isEdit) {
          title = this.$t("Document Updated")
        }
        Swal.fire(this.$t("Success"), title, "success");
      } catch (err) {
        console.log(err);
        if (err?.response?.status === 400) {
          this.apiErrors = err.response.data;
        } else {
          backendErrorSwal(err, err?.response?.data?.detail);
        }
      } finally {
        this.isSaving = false;
      }
    },

    onLegalPersonFiscalFormInput(form) {
      this.form = form;
    },
  },
};
</script>
