var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading,
      "rounded": "sm"
    }
  }, [_c('div', {
    staticClass: "form row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Country'),
      "rules": _vm.rules.country
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('euro-select', {
          attrs: {
            "label": "".concat(_vm.$t('Country'), "*"),
            "error-messages": errors,
            "options": _vm.countriesSelect,
            "searchable": ""
          },
          model: {
            value: _vm.form.country,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "country", $$v);
            },
            expression: "form.country"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Fiscal Code'),
      "vid": "fiscalid",
      "rules": _vm.rules.fiscalid
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('euro-input', {
          attrs: {
            "label": "".concat(_vm.$t('Fiscal Code'), "*"),
            "error-messages": errors
          },
          model: {
            value: _vm.form.fiscalid,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "fiscalid", $$v);
            },
            expression: "form.fiscalid"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-6"
  }, [_c('validation-provider', {
    attrs: {
      "vid": "from_date",
      "name": _vm.$t('From date'),
      "rules": _vm.rules.from_date
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('date-picker', {
          attrs: {
            "overrides": _vm.fromDateOverrides,
            "error-messages": errors,
            "placeholder": "YYYY-MM-DD",
            "label": _vm.$t('From date')
          },
          model: {
            value: _vm.form.from_date,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "from_date", $$v);
            },
            expression: "form.from_date"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-6"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('To date'),
      "vid": "to_date",
      "rules": _vm.rules.to_date
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('date-picker', {
          attrs: {
            "overrides": _vm.toDateOverrides,
            "error-messages": errors,
            "label": _vm.$t('To date')
          },
          model: {
            value: _vm.form.to_date,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "to_date", $$v);
            },
            expression: "form.to_date"
          }
        })];
      }
    }])
  })], 1), !_vm.optional ? _c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    staticClass: "w-100",
    attrs: {
      "name": _vm.$t('Attachment'),
      "vid": "attachment_tus",
      "rules": _vm.rules.attachment
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('euro-tus-file-input', {
          attrs: {
            "label": _vm.$t('Attachment'),
            "error-messages": errors,
            "accept": "application/pdf",
            "hint": _vm.$t('Accept .pdf - max size 20MB'),
            "attachment": _vm.existingForm.attachment
          },
          on: {
            "change": function change(tusId) {
              _vm.form.attachment_tus = tusId;
            }
          }
        })];
      }
    }], null, false, 2252897964)
  })], 1) : _vm._e()])]);

}
var staticRenderFns = []

export { render, staticRenderFns }